import * as Sentry from "@sentry/browser";
import { Elm } from "./Main.elm";

const WindowPort = require("./Ports/window.js");
const GooglePlacesPort = require("./Ports/google-places.js");
const GoogleRecaptchaPort = require("./Ports/google-recaptcha.js");
// const GoogleAnalyticsPort = require('./Ports/google-analytics.js')
const LocalStorage = require("./Ports/local-storage.js");
const NavigationPort = require("./Ports/navigation.js");
const SentryPort = require("./Ports/sentry.js");
const StripePort = require("./Ports/stripe.js");
const ThemePort = require("./Ports/theme.js");
const CustomJavascriptPort = require("./Ports/custom-javascript.js");
const PageTitlePort = require("./Ports/page-title.js");
const OnBeforeUnloadPort = require("./Ports/onBeforeUnload.js");
const OnElementScrollPort = require("./Ports/onElementScroll.js");
const OnTabSelectPort = require("./Ports/onTabSelect.js");
const IntercomPort = require("./Ports/intercom.js");
const FullStoryPort = require("./Ports/fullstory");
const FaviconPort = require("./Ports/favicon.js");
const IdleTimePort = require("./Ports/idle-time.js");

const sentryDsn = `https://${CONFIGURATION.sentryKey}@${CONFIGURATION.sentryOrganizationId}.ingest.sentry.io/${CONFIGURATION.sentryProjectId}`;
Sentry.init({
  dsn: sentryDsn,
  environment: CONFIGURATION.sentryEnvironment,
  release: CONFIGURATION.sentryReleaseVersion,
  maxValueLength: 1000,
  ignoreErrors: ["Cannot resolve iframe with unknown index"],
  denyUrls: [/extensions\//i],
});
Sentry.setExtra("api_version", CONFIGURATION.apiVersion);

// Initial data passed to Elm (should match `Flags` defined in `Shared.elm`)
// https://guide.elm-lang.org/interop/flags.html
var flags = {
  locationHref: location.href,
  accountToken: LocalStorage.getItem("accountToken"),
  bustCacheRandom: Math.random().toString(36).slice(2),
  accountPracticeId: LocalStorage.getItem("accountPracticeId"),
  apiUrl: CONFIGURATION.apiUrl,
  apiVersion: CONFIGURATION.apiVersion || null,
  emrNotesUrl: CONFIGURATION.emrNotesUrl,
  emrNotesVersion: CONFIGURATION.emrNotesVersion || null,
  emrCalendarsUrl: CONFIGURATION.emrCalendarsUrl,
  emrCalendarsVersion: CONFIGURATION.emrCalendarsVersion || null,
  release: CONFIGURATION.release || null,
  currentTime: new Date().getTime(),
  language: LocalStorage.getItem("hint-language") || navigator.language,
};

// Start our Elm application
const app = Elm.Main.init({
  node: document.getElementById("elm-main"),
  flags: flags,
});

// Ports go here
// https://guide.elm-lang.org/interop/ports.html
WindowPort.add(app);
FullStoryPort.add(app, CONFIGURATION.fullStoryOrgId);
GooglePlacesPort.add(app, CONFIGURATION.googleMapsKey);
GoogleRecaptchaPort.add(app);
// GoogleAnalyticsPort.add(app);
LocalStorage.add(app);
NavigationPort.add(app);
SentryPort.add(app, CONFIGURATION.env);
StripePort.add(app, CONFIGURATION.stripePublishableKey);
ThemePort.add(app);
CustomJavascriptPort.add(app);
PageTitlePort.add(app);
OnBeforeUnloadPort.add(app);
OnElementScrollPort.add(app);
OnTabSelectPort.add(app);
IntercomPort.add(app, CONFIGURATION.intercomAppId);
FaviconPort.add(app);
IdleTimePort.add(app);

if (CONFIGURATION.statuspageEmbedCode) {
  window.setupStatusPage(CONFIGURATION.statuspageEmbedCode);
}

if (
  CONFIGURATION.newRelicAccountId &&
  CONFIGURATION.newRelicLicenseKey &&
  CONFIGURATION.newRelicApplicationId &&
  typeof setupNewRelic !== "undefined"
) {
  setupNewRelic(
    CONFIGURATION.newRelicAccountId,
    CONFIGURATION.newRelicLicenseKey,
    CONFIGURATION.newRelicApplicationId,
  );
}

// detect if device is an iOS device and disable auto-zoom when clicking into elements
// https://stackoverflow.com/a/57527009

if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
  const el = document.querySelector("meta[name=viewport]");

  if (el !== null) {
    let content = el.getAttribute("content");
    let re = /maximum-scale=[0-9.]+/g;

    if (re.test(content)) {
      content = content.replace(re, "maximum-scale=1.0");
    } else {
      content = [content, "maximum-scale=1.0"].join(", ");
    }

    el.setAttribute("content", content);
  }
}
